.about-content {
  margin-top: 10vh;
  margin-bottom: 10vh;
  color: #FFF !important;
  font-size: 24px;
  text-align: right;
  padding-right: 5vw;
  padding-left: 5vw;
}

.about-line{
  color: white;
  background-color: #FFFFFF;
  width: 250px;
  border: 2px solid #FFFFFF;
  border-radius: 15px;
  opacity: 1;
  margin: 0px;
}

.about-div {
  margin-top: 15vh;
  display: flex;
  justify-content: center;
  align-items:center;
  width: 100%;
}

.about-text {
  font-size: 20px;
  color: #FFF;
  width: 75%;
  font-weight: 400;
  margin-bottom: 15vh;
}

.about-contact {
  color: #fff;
  font-size: 32px;
  font-weight: 700;
  text-align: center;
}

.about-contact:hover {
  color: silver;
}

.row-mobile {
  margin-top: 5vh;
}

.row-desktop {
  margin-bottom: 20vh;
}

.text-box {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align:center;  
}

.about-video {
  position: relative;
  width: 100vw;
}

.heading-primary {
  margin-bottom: "10vh";
  color: "white";
  font-size: "50px";
  font-weight: 400;
  color:#fff;
}

.heading-primary-main {
    display: block;
    font-size: 64px;
    font-weight: 300;
    letter-spacing: 2px;
    animation-name: moveInleft;
    animation-duration: 3s;
}

.heading-primary-sub {
    display: block;
    font-size: 96px;
    font-weight: 700;
    letter-spacing: 2px;
    animation-name: moveInRight;
    animation-duration: 3s;
}

@keyframes moveInleft {
  0% {
      opacity: 0;
      transform: translateX(-1000px);
  }

  80% {
      transform: translateX(0px);
  }
  100% {
      opacity: 1;
      transform: translate(0);
  }
}

@keyframes moveInRight {
  0% {
      opacity: 0;
      transform: translateX(1000px);
  }

  80% {
      transform: translateX(-0px);
  }
  
  100% {
      opacity: 1;
      transform: translate(0);
  }
}

@media screen and (max-width: 720px) {
  .heading-primary-main {
    display: block;
    font-size: 35px;
    margin-bottom: 10px;
    font-weight: 700;
    letter-spacing: 2px;
    animation-name: moveInleft;
    animation-duration: 3s;
  }

  .heading-primary-sub {
    display: block;
    font-size: 65px;
    font-weight: 700;
    letter-spacing: 2px;
    animation-name: moveInRight;
    animation-duration: 3s;
  }

  .about-text {
    font-size: 18px;
  }
  
  .about-div {
    margin-top: 10vh;
  }
}