.sub-text {
  margin-bottom: 20px;
  text-align: center;
  font-size: 25px;
}

.sub-text-subscribe {
  margin-top: 60px;
  text-align: center;
  font-size: 25px;
  color: #32CD32;
}

.sub-text-mobile {
  margin-bottom: 15px;
  text-align: center;
  font-size: 20px;
}

.subs-form input {
    display: block;
    width: 100%;
    margin: auto;
    margin-top: 20px;
    font-size: 14px;
    color: #fff;
    box-sizing: border-box;
    border: 0;
    outline: 0;
    border-bottom: 1px solid #fff;
    font-size: 1.2rem;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
  }

.subs-content .form-control {
  background-color: black !important;
  height: 55px !important;
  border-color: black !important;
  width: 100% !important;
  border: 0 !important;
  border-radius: 0px !important;
  outline: 0 !important;
  border-bottom: 1px solid #fff !important;
  font-size: 1.2rem !important;
}

.react-tel-input {
  margin-top: 10px !important;
  color: #fff !important;
}

.flag-dropdown {
  background-color: black !important;
  border-radius: 0px !important;
  border-color: black !important;
}

.selected-flag {
  background-color: black !important;
}

.country-list{
  background-color: black !important;
}

.country-list .country.highlight {
  background-color: black !important;
}

.country-list .country:hover {
  background-color: #00b200 !important;
}

.subs-button {
  background-color: transparent !important;
  color: #fff !important;
  border: 2px solid #fff !important;
  border-radius: 50px;
  width: 95% !important;
  height: 55px !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  line-height: 55px !important;
  margin-top: 40px !important;
}

.subs-button:hover {
  background: #fff !important;
  color: #000000 !important;
}

.subs-field {
  border-radius: 0px;
  border: none;
  padding: 16px;
  background: black;
}
.hidden {
  visibility: hidden;
}
.show {
  visibility: visible;
}

@keyframes mymove {
  0%   {left: 50%;}
  100%  {left: 120%;}
}

.subs-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.subs-wrapper .subs-content {
  background: black;
  padding: 0px;
  width: 100%;
  max-width: 500px;
  min-width: 200px;
  border-radius: 10px;
  text-align: center;
}

.subs-wrapper .subs-content footer input {
  border: unset;
  background-color: white;
  padding: var(--padding-15);
  font-size: 13px;
  border-radius: var(--radius-50);
  width: 250px;
}

.subs-wrapper .subs-content footer button {
  background-color: var(--button-background);
  color: #fff;
  border: unset;
  width: 120px;
  border-radius: var(--radius-50);
  padding: var(--padding-15);
  margin-left: 10px;
  cursor: pointer;
}

.subs-wrapper .subs-content footer input:focus, .subs-wrapper .subs-content footer button:focus {
  outline: none;
}

@media screen and (max-width: 720px) {
  .subs-wrapper .subs-content {
    margin-right: 20px !important;
    margin-left: 20px !important;
    padding: var(--padding-15);
  }
  .subs-wrapper .subs-content footer input {
    width: 200px;
    margin-bottom: 10px;
  }
}