body {
  background-color: black !important;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
	font-family: "comic_sans";
	src: local("comic_sans"), url(../src/assets/fonts/comic_sans.ttf) format("truetype");
}

@font-face {
	font-family: "NexaLight";
	src: local("NexaLight"), url(../src/assets/fonts/NexaLight.otf) format("opentype");
}

@font-face {
	font-family: "Montserrat";
	src: local("Montserrat"), url(../src/assets/fonts/Montserrat.ttf) format("truetype");
}
