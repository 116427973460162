.spotlight-line{
  color: white;
  background-color: #FFFFFF;
  width: 250px;
  border: 2px solid #FFFFFF;
  border-radius: 15px;
  opacity: 1;
  margin: 0px;
}

.row-spotlight {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8vh;
}

.spotlight-title {
  margin-top: 6vh;
  margin-bottom: 6vh;
}

.spotlight-img {
  width: 312px;
  height: 312px;
  cursor: pointer;
}

.read-all {
  color: #FFFFFF;
  font-weight: 700;
  font-size: 20px;
}

.collab-title {
  color: #FFFFFF;
  margin-top: 30px;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
}

.sample {
  margin-left: 10vw;
  margin-right: 10vw;
  width: 80vw;
}

.slick-prev:before, .slick-next:before { 
  font-family: FontAwesome !important;
  font-size: 20px !important; 
  color: white !important; 
  -webkit-font-smoothing: antialiased !important; 
  -moz-osx-font-smoothing: grayscale !important; 
}   

.slick-prev:before { 
  content: "\f053" !important; 
}

.slick-next:before { 
  content: "\f054" !important; 
}


@media screen and (max-width: 720px) {
  .row-spotlight {
    margin-bottom: 0vh;
  }

  .collab-title {
    margin-top: 0px;
    margin-bottom: 50px;
  }
}