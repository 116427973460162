.correct {
  background-color: #3fa05c !important;
  pointer-events: none;
}

.incorrect {
  background-color: rgb(181, 87, 87) !important;
  pointer-events: none;
  text-decoration: line-through;
}

.clear {
  pointer-events: none;
}

.multiple-choices {
  animation: pop-in 1s ease-out;
}

.btn-xilver-collab {
  background-color: transparent !important;
  color: #fff !important;
  border: 2px solid #fff !important;
  border-radius: 50px !important;
  width: 80% !important;
  height: 55px !important;
  font-size: 20px !important;
  padding: 0px !important;
  line-height: 55px !important;
  font-weight: 800 !important;
  margin-top: 40px !important;
}

.btn-xilver-collab:hover {
  background-color: white !important;
  color: #000000 !important;
}

@keyframes pop-in {
  0% {
    transform: scale(0) translateY(-100px);
    opacity: 0;
  }

  80% {
    transform: scale(1.05) translateY(10px);
    opacity: 1;
  }

  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
  }
}

/* media queries */
@media only screen and (min-width: 320px) {
  ol {
    padding: 0 2rem;
  }
}

@media only screen and (min-width: 768px) {
  ol {
    padding: 0 10rem;
  }
  .next-btn {
    font-size: 2rem;
  }
}

@media only screen and (min-width: 1200px) {
  ol {
    padding: 0 20rem;
  }
  .next-btn {
    font-size: 3rem;
  }
}

.modal-collab .modal-content {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000 ;
  border: 1px solid gray;
  border-radius: 50px;
  color: #fff;
}

.modal-collab .modal-header {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border: #000000;
}

.modal-collab-body {
  margin-bottom: 20px;
}

.modal-collab {
  max-width: 60vmax !important; 
  width: 100%;
}

.modal-collab-info {
  padding-right: 50px !important;
}

.modal-collab-title {
  display: flex;
  margin-top: 30px;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  font-weight: 400;
  margin-bottom: 0px;
}

.close {
  position: absolute;
  right: 50px;
  top: 45px;
  color: white;
  background-color: black;
  font-size: 20px;
  margin-left: auto; 
  margin-top: 0;
}

.heading-one {
  font-size: 1.5rem;
  line-height: 50px;
}

.heading-two {
  font-size: 1.2rem;
  line-height: 35px;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
} 

@media screen and (max-width: 720px) {
  .modal-collab-title {
    font-size: 20px;
  }

  .close {
    right: 30px;
    font-size: 10px;
    border: 0px;
  }

  .modal-collab {
    width: 100%;
  }

  .heading-one {
    font-size: 1.25rem;
  }

  .heading-two {
    font-size: 1.15rem;
  }

  .modal-collab-info {
    padding-right: 12px !important;
    padding-top: 25px !important;
  }
}