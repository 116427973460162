.card {
  background-color: black !important;
}

.card .card-button {
  position: absolute;
  top: 43%;
  width: 0px;
  height: 0px;
}

.card .card-button.right {
  right: 4%;
}

.card .card-button.left {
  left: 4%;
}

.react-stacked-center-carousel {
  height: 100%;
  padding: 0 0;
}

.card-card {
  transition: all 300ms ease;
  cursor: pointer;
  width: 100%;
  border-radius: 15px;
  height: auto;
  position: relative;
  background-color: black;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2), 0 0 4px rgba(0, 0, 0, 0.05);
}

.card-card:hover {
  transform: scale(1.05);
}

.react-stacked-center-carousel-slide-0 .card-card {
  cursor: default;
}

.react-stacked-center-carousel-slide-0 .card-card:hover {
  transform: none;
}

.fill {
  width: 100%;
  height: 100%;
}

.card-card .card-overlay {
  user-select: none;
  position: absolute;
  background-color: #00000099;
  transition: all 300ms ease;
  border-radius: 15px;
}

.card-card:hover .card-overlay {
  background-color: #0000002d;
}

.react-stacked-center-carousel-slide-0 .card-overlay {
  background-color: #00000099 !important;
}

.card-card .cover-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30vw;
  height: 45vh;
  border-radius: 15px;
  object-fit: fill;
}

.card-card .cover-video {
  width: 30vw;
  height: 45vh;
  border-radius: 15px;
  object-fit: fill;
}

.card-card .cover {
  position: absolute;
  transition: opacity 300ms ease;
  width: 40vw;
}

.react-stacked-center-carousel-slide-0 .card-card .cover {
  transition: opacity 300ms ease, z-index 0ms 300ms;
}

.card-card .cover.on {
  opacity: 1;
  z-index: 1;
}

.card-card .cover.off {
  opacity: 0;
  z-index: -1;
}

.card-card .detail {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;}

.card-card .discription {
  display: flex;
  flex-direction: column;
  margin: 10px;
}

@media screen and (max-width: 720px) {
  .card .card-button.right {
    right: 2%;
  }
  
  .card .card-button.left {
    left: 2%;
  }

  .card-card .cover-image {
    width: 80vw;
    height: 300px;
  }

  .card-card .cover-video {
    width: 80vw;
    height: 300px;
    border-radius: 15px;
    object-fit: fill;
  }
}

