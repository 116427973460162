.section_one{
  position: relative;
}

.video-landing {
  width: 100%;
  position: relative;
  min-height: 100vh;
}

.video-subsection {
  position: relative;
  width: 100%;
  min-height: 65vh;
}

.sub-mobile {
  position: relative;
  width: 100%;
  min-height: 40vh;
}

.xilver-content {
  color: #FFF !important;
  font-size: 24px;
  text-align: center;
  padding-right: 10vw;
  padding-left: 5vw;
  padding-top: 40px;
}

.content-mobile {
  color: #FFF !important;
  font-size: 18px;
  margin-top: 5vh;
  padding: 12px;
}

.explore {
  color: white;
  font-size: 20px;
  font-weight: 700;
}

.stage {
  position: absolute;
  bottom: 0;
  right: 0;
  color: black;
  margin: 30px;
}

.icon {
  display: inline-block;
  width: 50px;
  height: 50px;
  background: #FFF;
  border-radius: 50%;
  text-align: center;
  font-size: 30px;
  cursor: pointer;
  color: #000;
  line-height: 50px;
}

.icon:after {
  pointer-events: none;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  content: '';
  box-shadow: inset 0 0 0 3px #FFF;
  transition: transform 0.2s, opacity 0.2s;
}

.icon:hover:after {
  transform: scale(2.45);
  opacity: 0.8;
}

.pulsing:before {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  content: '';
  box-shadow: inset 0 0 0 3px #FFF;
  transition: transform 0.2s, opacity 0.2s;
  animation: pulsing 2.7s infinite;
}

@keyframes pulsing {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.scrollDownContainer {
  cursor: pointer;
  bottom: 175px;
  display: flex;
  justify-content: center;
  font-weight: bold;
  align-items: center;
  left: 50%;
  position: absolute;
}

.scrollDownChevron {
  position: absolute;
  width: 28px;
  height: 8px;
  opacity: 0;
  transform: scale3d(0.5, 0.5, 0.5);
  animation: move 3s ease-out infinite;
}

.scrollDownChevron:first-child {
  animation: move 3s ease-out 1s infinite;
}

.scrollDownChevron:nth-child(2) {
  animation: move 3s ease-out 2s infinite;
}

.scrollDownChevron:before,
.scrollDownChevron:after {
  content: " ";
  position: absolute;
  top: 0;
  height: 100%;
  width: 51%;
  background: #fff;
}

.scrollDownChevron:before {
  left: 0;
  transform: skew(0deg, 30deg);
}

.scrollDownChevron:after {
  right: 0;
  width: 50%;
  transform: skew(0deg, -30deg);
}

@keyframes move {
  25% {
    opacity: 1;
  }
  33% {
    opacity: 1;
    transform: translateY(30px);
  }
  67% {
    opacity: 1;
    transform: translateY(40px);
  }
  100% {
    opacity: 0;
    transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
  }
}

.scrollDownText {
  display: block;
  position: inherit;
  top: 75px;
  font-size: 18px;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  white-space: nowrap;
  opacity: 0.25;
  animation: pulse 2s linear alternate infinite;
}

.btn-xilver-about {
  background-color: transparent;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 50px;
  width: 95%;
  height: 55px;
  font-size: 20px;
  line-height: 55px;
  font-weight: 800;
  margin-top: 40px;
}

.btn-xilver-about:hover {
  background-color: white;
  color: #000000;
}

.xilver-btn-text {
  font-weight: 600 !important;
}

@keyframes pulse {
  to {
    opacity: 1;
  }
}

@media only screen and (max-width: 576px) {
  .scrollDownContainer {
    cursor: pointer;
    bottom: 125px;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 50%;
    position: absolute;
  }
  .btn-xilver-about {
    width: 40vw;
    height: 50px;
    line-height: 50px;
    font-size: 15px;
    margin-top: 10px;
  }
}

@media only screen and (max-height: 576px) {
  .scrollDownContainer {
    cursor: pointer;
    bottom: 210px;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 50%;
    position: absolute;
  }
}
