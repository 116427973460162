.wrap {
  padding: 0px;
  top: -90px;
  position:relative;
  width: 100%;
}

h3 {
  font-size:2.4rem;
  font-weight: 600;
  text-align:center;
}

.clients {
    align-items: center;
    -webkit-box-align: center;
    display: grid;
    padding: 0px;
    gap: 4rem 0rem;
    grid-template-columns: auto auto;
    justify-items: center;
    margin: 0 auto;
    max-width: 1024px; 
  }

.clients li {
  list-style: none;
  margin: 0;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.clients img {
  height: 4rem;
}

.clients li:hover {
-webkit-transform: translateY(-0.3rem);
transform: translateY(-0.3rem); 
}

@media (min-width: 1024px) {
.clients {
    grid-template-columns: auto auto auto auto; 
  }
    
.clients img {
  height: 5rem; 
}}

/* --- Images (gray logo) --- */
.clients img {
  -webkit-filter: blackscale(100%) brightness(10%) contrast(10%);
          filter: blackscale(100%) brightness(10%) contrast(10%); }

.clients img:hover {
  background: none;
  -webkit-filter: grayscale(0%);
          filter: grayscale(0%);
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out; }

.partners-div {
  background: black;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 5;
  color: white;
  text-align: center;
}

.partners-round {
  border-radius: 20px;
  overflow: hidden;
  margin: 30px;
  position: relative;
}

.partners-video {
  object-fit: cover;
  display: block;
  position: relative;
  width: 100vw;
  height: 90vh;
  left: 0;
  top: 0;
  z-index: 0;
}

.partners-video-mobile {
  object-fit: fill;
  position: relative;
  width: 100vw;
  height: 95vh;
  left: 0;
  top: 0;
  z-index: 0;
}

.partner-overlay {
  position: absolute;
  z-index: 1;
  font-size: 50px;
  color: white;
  margin: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 85%;
  text-align: center;
}

/* .partners-round {
  border: 20px;
  border-radius: 20px !important;
}

.partners-video {
  border: 20px;
  border-radius: 20px !important;
  object-fit: cover;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 40px;
} */