#form {
  padding-top: 5rem;
  display: flex;
  width: 100%;
  gap: 20px;
  align-items: center;
  max-width: 700px;
  margin: 0 auto;
 }

 .contact-message {
  margin-top: 40px;
  text-align: center;
  font-size: 25px;
  font-weight: 800;
}

.form-group label {
  display: block;
  text-align: left;
}

.contact {
  position: relative;
  padding: 15px 0;
}

.contact form {
  position: relative;
  z-index: 1000;
}

.contact .form-group ::placeholder{
  color: rgb(255, 255, 255);
  font-weight: 600;
}

.contact .form-control {
  background-color: rgba(255,255,255,0.4);
  height: 55px;
  width: 80%;
  align-items: center;
  border-radius: 10px;
  background: white(0, 0, 0, 0.6);
  color: black;
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 12px;
}

.contact .form-control:focus,
.contact .form-control:hover {
  border: none;
  border-bottom: 2px solid rgba(255, 255, 255, 1);
  box-shadow: none;
}

.contact textarea {
  min-height: 100px;
}

.contact label {
  color: #fff;
  font-size: 1.25rem;
}

.form-group{
  padding-bottom: 10px;
}

.form-group .submit_btn {
  color: black;
  background: #fff;
  width: 80%;
  filter: brightness(90%);
  border-radius: 50px;
  padding: 10px 45px;
  font-size: 1.15rem;
  outline: none;
  font-weight: 700;
  margin-top: 20px;
  transition: 0.65s;
}

.form-group .submit_btn:hover {
  background: #fff;
  color: #000;
}

.contact:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 400px;
}

.contact:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}