.options {
  width: 35vw !important;
  margin: 10px !important;
  padding: 15px !important;
  border-radius: 5px !important;
  border-color: black !important;
  background: rgba(230, 236, 230, 0.837) !important;
}

.submit {
  width: 180px !important;
  margin-top: 20px !important;
  border-radius: 50px !important;
  padding: 12px !important;
  background: none !important;
  color: white !important;
  border-color: #1c9e41 !important;
  border-radius: 25px !important;
  box-shadow: -1px 3px 3px 0 rgba(219, 205, 205, 0.2) !important;
  transition: all 0.25s ease-out !important;
}

.submit:hover {
  background-color: #1c9e41 !important;
  position: relative !important;
  box-shadow: 0 0 0 1px rgba(80, 80, 80, .5) !important;
}

.center_image {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.shoe_img {
  padding: 40px;
}

.shoe_img_mobile {
  padding: 20px;
}
