.blog-data {
  color: #fff;
  font-weight: 400;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blog-para {
  width: 60vw;
}

.blog-para-2 {
  margin-top: 8vh;
  width: 60vw;
}

.button-group {
  margin-bottom: 20px;
}

.counter {
  display: inline;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 10px;
}

.posts {
  clear: both;
  list-style: none;
  padding-left: 0;
  width: 100%;
  text-align: left;
}

.posts li {
  background-color: #fff;
  border: 1.5px solid #d8d8d8;
  border-radius: 10px;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  word-wrap: break-word;
  min-height: 42px;
  box-shadow:8px 8px 5px #888888;
}

.status-box {
  height: 50px;
  margin-bottom: 20px;
}

.btn-xilver {
  background-color: transparent;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 50px;
  width: 50%;
  height: 55px;
  font-size: 20px;
  line-height: 55px;
  font-weight: 800;
  margin-top: 40px;
}

.btn-xilver:hover {
  background-color: white;
  color: #000000;
}

.comments-data {
  background-color: black;
  color: white;
  margin-top: 8vh;
}

.all-comments{
  margin-bottom: 40px;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: white;
  padding-bottom: 12px;
}

.zoomOnHover {
  transition: transform .2s;
}

.zoomOnHover:hover {
  transform: scale(1.2);
}

@media screen and (max-width: 720px) {
  .blog-data {
    color: #fff;
    font-weight: 400;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .blog-para {
    margin-top: 0vh;
    width: 75vw;
  }

  .blog-para-2 {
    margin-top: 6vh;
    width: 75vw;
  }

  .comments-data {
    margin-top: 5vh;
  }
}