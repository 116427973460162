.video-block {
  background: black;
  width: 100%;
  padding-top: 180px;
  position: relative;
  min-height: 100vh;
  z-index: 5;
  color: white;
  text-align: center;
}

.darken {
  filter: brightness(50%);
  opacity: 0.7;
}

.contact {
  top: -120px;
}

#video {
  object-fit: cover;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -9;
}
