.collab {
  background: black;
  width: 100%;
  position: relative;
  min-height: 100vh;
  z-index: 5;
  color: white;
  text-align: center;
}

.xrender {
  object-fit: cover;
  filter: brightness(100%);
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  max-width: 100vw;
  max-height: 100vh;
  left: 0;
  top: 0;
  opacity: 0.7;
  z-index: -9;
}

.sticky {
  position: fixed !important;
}

.video-display {
  display: block;
}