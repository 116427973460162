.static {
  position:absolute;
}

.static:hover {
  opacity:0;
}


.style_prevu_kit
{
    display:inline-block;
    border:0;
    padding: 0;
    position: relative;
    -webkit-transition: all 200ms ease-in;
    -webkit-transform: scale(1); 
    -ms-transition: all 200ms ease-in;
    -ms-transform: scale(1); 
    -moz-transition: all 200ms ease-in;
    -moz-transform: scale(1);
    transition: all 200ms ease-in;
    transform: scale(1);   

}

.movieXcar {
  display: inline-block;
  margin-top: 25px;
  font-size: 1.5rem;
  color: #fff;
  text-shadow: 1px 1px #dfd8d8;
}
